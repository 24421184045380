<template>
  <div class="opt-addresses">
    <div class="container">
      <h2 class="title">{{ $t("optFilial") }}</h2>

      <div class="opt-addresses__list">
        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Алматы</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Almaty')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Астана</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Nur-Sultan')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Шымкент</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Shymkent')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Караганда</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Karaganda')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Петропавловск</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Petropavlovsk')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Тараз</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Taraz')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Талдыкорган</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Taldykorgan')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Кызылорда</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Kyzylorda')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Туркестан</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Turkestan')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>

        <div class="opt-addresses__list-item">
          <div class="opt-addresses__list-name">Усть-Каменогорск</div>
          <div class="opt-addresses__list-wrapper">
            <div
              v-for="(item, index) in $t('opt-addresses__Ust-Kamenogorsk')"
              :key="index"
              class="opt-addresses__list-location"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>

      <div class="opt-addresses__info">
        <div class="opt-addresses__documents">
          <div v-if="prices.length" class="price-lists">
            <h3>{{ $t("optPrices") }}</h3>
            <div class="price-lists__items">
              <a
                v-for="price in prices"
                :key="price.id"
                :href="getDocumentFile(price)"
                class="price-lists__item"
                >{{ price.name }}</a
              >
            </div>
          </div>

          <div v-if="agreements.length" class="contracts">
            <h3>{{ $t("optDocuments") }}</h3>
            <div class="contracts__items">
              <a
                v-for="agreement in agreements"
                :key="agreement.id"
                :href="getDocumentFile(agreement)"
                class="price-lists__item"
                >{{ agreement.name }}</a
              >
            </div>
          </div>
        </div>

        <div class="opt-addresses__contacts">
          <h3>{{ $t("optContacts") }}</h3>
          <div class="opt-addresses__contacts-wrapper">
            <span>{{ $t("optContactsTitle") }}</span>
            <a href="tel:+77717040347">+7 (771) 704 03 47</a>
            <a href="tel:7766">{{ $t("optContactsPhone") }}</a>
            <a href="mailto:opt@magnum.kz" class="color-pink">opt@magnum.kz</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPriceAndContacts } from "@/api/page-opt";
export default {
  name: "OptAddresses",
  data() {
    return {
      prices: [],
      agreements: [],
    };
  },
  created() {
    getPriceAndContacts().then((res) => {
      this.agreements = res.attributes.contacts;
      this.prices = res.attributes.prices;
    });
  },
  methods: {
    getDocumentFile(item) {
      return process.env.VUE_APP_API_URL + item?.file?.data?.attributes?.url;
    },
  },
};
</script>
