import axios from "@/plugins/axios";

import qs from "qs";

export const getPriceAndContacts = async () => {
  const query = qs.stringify({
    populate: {
      prices: {
        populate: "*",
      },
      contacts: {
        populate: "*",
      },
    },
  });
  const response = await axios.get(`/page-opt?${query}`);
  return response.data.data;
};
